.Audience360Page,
.AudiencePage {
  width: 100%;
  max-width: 100%;
}

#CustomerSegments {
  .TableComponent {
    .ant-table {
      thead {
        .ant-table-cell {
          height: 40px;
          background: #fafafa;
          border: none;
        }
      }

      &-column-sorters {
        padding: 0px;
      }
    }
  }
}

.customer-segments-filter-dropdown {
  box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  .ant-checkbox-wrapper {
    padding: 5px 0 5px 0;
  }
}
